
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'caseUser'
    }
  },
  setup(props: any, context: any) {
    const urlData = location.hash.substring(
      location.hash.indexOf('/') + 1,
      location.hash.indexOf('?')
    )
    let identityOptions = []
    if (urlData == 'pengJang-confirm') {
      identityOptions = [
        { code: 1, value: '居民身份证' },
        { code: 7, value: '统一社会信用代码' }
      ]
    } else {
      identityOptions = [
        { code: 1, value: '居民身份证' },
        { code: 2, value: '中国公民护照' },
        { code: 3, value: '台湾居民来往大陆通行证' },
        { code: 4, value: '港澳居民来往内地通行证' },
        { code: 7, value: '统一社会信用代码' },
        { code: 9, value: '律师证' }
      ]
    }

    const identityType = ref(1)
    const proxyIdentityType = ref(1)
    const agentIdentityType = ref(1)
    const changeIdentityType = (val: any) => {
      context.emit('change-identity-type', val, props.type)
    }
    return {
      identityOptions,
      identityType,
      proxyIdentityType,
      agentIdentityType,
      changeIdentityType
    }
  }
})
