
import { defineComponent, reactive, ref, nextTick, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// import sign from '@/components/sign.vue'
import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '../commons/SessionStorageUtils'
import AxiosAdapter from '../request/AxiosAdapter'
// import { ElMessage } from 'element-plus'
import LinkAge from '@/components/Selection.vue'
import UserType from '@/components/UserType.vue'
import UserUpload from '@/components/UserUpload.vue'
import PreView from '../components/preview/PreView'
import { StringObjectType } from '../commons/Types'
import { requestFunc, getRefFunc } from '../service/Linkage'
import { mobileReg, emailReg, IDReg } from '../commons/unit'
import { throwCustomFunction } from '../commons/ErrorHandle'
import signature from './signature.vue'

export default defineComponent({
  name: 'WestCityConfirm',
  components: { LinkAge, UserType, UserUpload, HeaderTit, signature },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const signature = ref(null)
    const agentSignature = ref(null)
    const userUpload = ref(null)
    const userType = ref(null)
    const areaName = ref('')
    const signShow = ref(false)
    // const receiverName = ref()
    const signSrc = ref('')
    const signImgStr = ref()
    const caseUserType = ref(1) //受送达类型s
    const proxyType = ref(1) //受送达类型子类型
    const newData =
      new Date().getFullYear() +
      '年' +
      (new Date().getMonth() + 1) +
      '月' +
      new Date().getDate() +
      '日'
    let courtName = ''
    const getUrl = process.env.VUE_APP_BASE_API
    const verifyCode = ref('')
    const dialogVisible = ref(false)
    const urls = reactive({
      frontUrl: '',
      reverseUrl: ''
    })

    const typeOptions = reactive({
      userElectronicType: ['2'],
      agentElectronicType: []
    })
    const areaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })
    const agentAreaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })

    const signs = reactive([
      {
        text: '受送达人或诉讼代理人（签名或盖章）',
        bindField: signature,
        title: 'signature',
        isHavingImg: false,
        img: ''
      }
    ])
    const isElectronicList = ref([
      {
        code: true,
        name: '同意'
      },
      {
        code: false,
        name: '不同意'
      }
    ])
    const identityTypeList = ref([
      {
        code: 1,
        name: '居民身份证'
      },
      {
        code: 2,
        name: '中国公民护照'
      },
      {
        code: 7,
        name: '统一社会信用代码'
      },
      {
        code: 9,
        name: '律师证'
      }
    ])
    const province = ref(0)
    const agentProvince = ref(0)
    // 初始化省
    requestFunc().then((value) => (areaOptions.shengList = value))
    requestFunc().then((value) => (agentAreaOptions.shengList = value))

    let cityResolve
    let districtResolve
    const cityPromise = new Promise<void>((resolve) => (cityResolve = resolve))
    const districtPromise = new Promise<void>(
      (resolve) => (districtResolve = resolve)
    )
    let agentCityResolve
    let agentDistrictResolve
    const agentCityPromise = new Promise<void>(
      (resolve) => (agentCityResolve = resolve)
    )
    const agentDistrictPromise = new Promise<void>(
      (resolve) => (agentDistrictResolve = resolve)
    )

    const city = getRefFunc(areaOptions, 'shiList', province, true, cityResolve)

    const district = getRefFunc(
      areaOptions,
      'quList',
      city,
      false,
      districtResolve
    )

    const agentCity = getRefFunc(
      agentAreaOptions,
      'shiList',
      agentProvince,
      true,
      agentCityResolve
    )

    const agentDistrict = getRefFunc(
      agentAreaOptions,
      'quList',
      agentCity,
      false,
      agentDistrictResolve
    )

    const ruleForm: any = reactive({
      id: '',
      name: '',
      identityType: '' || null,
      identityNumber: '',
      mobilephone: '',
      mobilephone2: '',
      userName: '',
      isElectronic: false,
      detailAddress: '',
      email: '',
      confirm: true,
      institutionId: '',
      signature: '',
      agentSignature: '',
      province,
      city,
      district,
      agentCity,
      agentProvince,
      agentDistrict,
      userProvinceCode: '',
      userCityCode: '',
      userDistrictCode: '',
      agentProvinceCode: '',
      agentCityCode: '',
      agentDistrictCode: '',
      postalCode: '',
      userIdentityType: '',
      userIdentityNumber: '',
      userMobilephone: '',
      userDetailAddress: '',
      userIsElectronic: true,
      userEmail: '',
      userElectronicType: '',
      userFoxNumber: '',
      agentName: '',
      agentIdentityType: '',
      agentIdentityNumber: '',
      agentMobilephone: '',
      agentIsElectronic: true,
      agentElectronicType: '',
      remark: '',
      agentEmail: '',
      agentFoxNumber: ''
      // agentName: ''
    })
    const isShow = ref(1)
    const redisKey = ref('')
    // const userElectronicType = reactive([])
    // const agentElectronicType = reactive([])
    if (route.query && typeof route.query.courtName === 'string') {
      courtName = route.query.courtName
    }

    const submit = async () => {
      if (!ruleForm.userName) {
        throwCustomFunction('请输入当事人名称')
      }
     if(!ruleForm.userIdentityType){
      throwCustomFunction('请选择当事人证件类型')
     }
     if(!ruleForm.userIdentityNumber){
      throwCustomFunction('请输入当事人证件号')
     }
      if (ruleForm.userIdentityType == 1) {
        if (!IDReg.test(ruleForm.userIdentityNumber)) {
          throwCustomFunction('请输入当事人正确的身份证号')
        }
      }
      if(!ruleForm.userMobilephone){
        throwCustomFunction('请输入当事人手机号')
      }

      if (!mobileReg.test(ruleForm.userMobilephone)) {
        throwCustomFunction('请输入正确的手机号')
      }

      if (ruleForm.mobilephone2) {
        if (!mobileReg.test(ruleForm.mobilephone2)) {
          throwCustomFunction('请输入正确的其他联系方式')
        }
      }

      if (!ruleForm.province || !ruleForm.city || !ruleForm.district) {
        throwCustomFunction('请选择当事人所在地区')
      }
      if (!ruleForm.userDetailAddress) {
        throwCustomFunction('请输入当事人详细地址')
      }
      let userArr: any[] = []
      userArr = typeOptions.userElectronicType
      if (ruleForm.userIsElectronic) {
        if (typeOptions.userElectronicType.length < 1) {
          throwCustomFunction('同意电子送达后请选择具体送达方式')
        }
        if (userArr && userArr.indexOf('2') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入电子邮件地址')
          }
        }

        if (ruleForm.userEmail) {
          if (!emailReg.test(ruleForm.userEmail)) {
            throwCustomFunction('请输入正确电子邮件地址')
          }
        }
      }

      if (caseUserType.value === 2) {
        if (!ruleForm.agentName) {
          throwCustomFunction('请输入代理人名称')
        }
        if (!ruleForm.agentIdentityType) {
          throwCustomFunction('请选择代理人证件类型')
        }
        if (!ruleForm.agentIdentityNumber) {
          throwCustomFunction('请输入代理人证件号')
        }
        if (
          ruleForm.agentIdentityType == 1 &&
          !IDReg.test(ruleForm.agentIdentityNumber)
        ) {
          throwCustomFunction('请输入代理人正确的身份证号')
        }
        if (!ruleForm.agentMobilephone) {
          throwCustomFunction('请输入代理人手机号码')
        }

        if (!mobileReg.test(ruleForm.agentMobilephone)) {
        throwCustomFunction('请输入正确的代理人手机号')
      }

        if (!(agentProvince.value && agentCity.value && agentDistrict.value)) {
          throwCustomFunction('请选择代理人所在地区')
        }
   
        if (!ruleForm.agentDetailAddress) {
          throwCustomFunction('请输入代理人详细地址')
        }

        let arr: any[] = []
        arr = typeOptions.agentElectronicType
        if (ruleForm.agentIsElectronic) {
          if (typeOptions.agentElectronicType.length < 1) {
            throwCustomFunction('代理人同意电子送达后请选择具体送达方式')
          }
          if (arr && arr.indexOf('2') > -1) {
            if (!ruleForm.agentEmail) {
              throwCustomFunction('请输入代理人电子邮件地址')
            }
          }
          if (ruleForm.agentEmail) {
            if (!emailReg.test(ruleForm.agentEmail)) {
              throwCustomFunction('请输入代理人正确电子邮件地址')
            }
          }
        }
      }

      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }
      if (!route.query.institutionId) {
        throwCustomFunction('无法获取当前法院')
      }
      if (caseUserType.value == 1) {
        ruleForm.agentIsElectronic = false
      }
      ruleForm.institutionId = route.query.institutionId
      ruleForm.redisKey = redisKey.value
      ruleForm.userProvinceCode = ruleForm.province
      ruleForm.userCityCode = ruleForm.city
      ruleForm.userDistrictCode = ruleForm.district
      ruleForm.agentProvinceCode = ruleForm.agentProvince
      ruleForm.agentCityCode = ruleForm.agentCity
      ruleForm.agentDistrictCode = ruleForm.agentDistrict
      ruleForm.userElectronicType = typeOptions.userElectronicType.toString()
      ruleForm.agentElectronicType = typeOptions.agentElectronicType.toString()
      if (!ruleForm.userIsElectronic) {
        ruleForm.userElectronicType = ''
        ruleForm.userFoxNumber = ''
        ruleForm.userEmail = ''
      }
      if (!ruleForm.agentIsElectronic) {
        ruleForm.agentElectronicType = ''
        ruleForm.agentFoxNumber = ''
        ruleForm.agentEmail = ''
      }
      let canSubmit = false
      signs.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (signImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = signImgStr.value
        }
        // ruleForm[current.title] = val.submit(80, 50)
        // }ruleForm[current.title] = signImgStr.value
      })
      if (!canSubmit) {
        throwCustomFunction('请手写签名')
      }
      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }
      const data: any = await AxiosAdapter.post({
        url: '/confirmAddress/addAddressForWestCity',
        data: ruleForm
      })

      // ElMessage.success({
      //   type: 'success',
      //   message: '保存成功',
      //   duration: 2000
      // })
      PreView(data, router)
      // router.push({
      //   path: '/confirm-address-detail',
      //   query: {
      //     url: data
      //   }
      // })
    }

    const goBack = (type: number) => {
      // router.push({
      //   path: '/west-city-confirmation'
      // })
      // caseUserType.value = 1
      // proxyType.value = 1
      isShow.value = type
    }
    const modifyMessage = () => {
      dialogVisible.value = true
    }
    const cancelGetCode = () => {
      dialogVisible.value = false
      verifyCode.value = ''
    }
    const verifyCodeFun = async (verifyCode?: string) => {
      // const params = {
      //   code: verifyCode,
      //   institutionId: route.query.institutionId
      // }

      const data: any = await AxiosAdapter.post({
        url:
          '/confirmAddress/checkRandomCode?institutionId=' +
          route.query.institutionId +
          '&code=' +
          verifyCode
      })

      ruleForm.id = data.id
      // ruleForm.provinceCode = data.provinceCode
      // ruleForm.cityCode = data.cityCode
      // ruleForm.districtCode = data.districtCode
      ruleForm.institutionId = data.institutionId
      ruleForm.name = data.name
      ruleForm.userIdentityType = data.userIdentityType
      ruleForm.userIdentityNumber = data.userIdentityNumber
      ruleForm.userMobilephone = data.userMobilephone
      ruleForm.userDetailAddress = data.userDetailAddress
      ruleForm.userIsElectronic = data.userIsElectronic
      ruleForm.userEmail = data.userEmail
      ruleForm.userFoxNumber = data.userFoxNumber
      ruleForm.agentIdentityType = data.agentIdentityType
      ruleForm.agentIdentityNumber = data.agentIdentityNumber
      ruleForm.agentMobilephone = data.agentMobilephone
      ruleForm.agentDetailAddress = data.agentDetailAddress
      ruleForm.agentIsElectronic = data.agentIsElectronic
      ruleForm.agentElectronicType = data.agentElectronicType
      ruleForm.agentEmail = data.agentEmail
      ruleForm.agentFoxNumber = data.agentFoxNumber
      ruleForm.agentName = data.agentName
      ruleForm.userFoxNumber = data.userFoxNumber
      ruleForm.userElectronicType = data.userElectronicType
      ruleForm.email = data.email
      ruleForm.postalCode = data.postalCode
      ruleForm.detailAddress = data.detailAddress
      ruleForm.isElectronic = true
      ruleForm.mobilephone = data.mobilephone
      ruleForm.mobilephone2 = data.mobilephone2
      province.value = data.userProvinceCode
      city.value = data.userCityCode
      district.value = data.userDistrictCode
      agentProvince.value = data.agentProvinceCode
      agentCity.value = data.agentCityCode
      agentDistrict.value = data.agentDistrictCode
      ruleForm.userProvinceCode = data.userProvinceCode
      ruleForm.userCityCode = data.userCityCode
      ruleForm.userDistrictCode = data.userDistrictCode
      ruleForm.agentProvinceCode = data.agentProvinceCode
      ruleForm.agentCityCode = data.agentCityCode
      ruleForm.agentDistrictCode = data.agentDistrictCode
      ruleForm.remark = data.remark
      ruleForm.userName = data.userName
      // ruleForm.signature = data.signature

      cityPromise.then(() => (city.value = data.userCityCode))
      districtPromise.then(() => (district.value = data.userDistrictCode))
      agentCityPromise.then(() => (agentCity.value = data.agentCityCode))
      agentDistrictPromise.then(
        () => (agentDistrict.value = data.agentDistrictCode)
      )
      caseUserType.value = data.userType
      proxyType.value = data.proxyType
      const val: any = userType.value
      if (val) {
        val.userValue = data.userType
        val.proxyValue = data.proxyType
      }
      nextTick(() => {
        const uploadVal: any = userUpload.value
        if (data.userType == 1) {
          if (data.proxyType == 1) {
            uploadVal.identityPhotoUrl = data.identityPhotoUrl || ''
            uploadVal.urlsList.caseUser = data.identityPhotoUrl.split(',')
            uploadVal.urlsList.identityPhotoBackUrl =
              data.identityPhotoBackUrl.split(',')
            uploadVal.companyCertificateUrl = ''
            uploadVal.identityPhotoUrl = ''
            uploadVal.proxyIdentityPhotoUrl = ''
            uploadVal.delegationUrl = ''
            uploadVal.otherUrl = ''
          }
          if (data.proxyType == 2) {
            uploadVal.urlsList.legalIdentity = data.identityPhotoUrl.split(',')
            uploadVal.urlsList.legalWorkIdentity =
              data.companyCertificateUrl.split(',')
            uploadVal.urlsList.identityPhotoBackUrl =
              data.identityPhotoBackUrl.split(',')
            uploadVal.identityPhotoUrl = data.identityPhotoUrl || ''
            uploadVal.companyCertificateUrl = data.companyCertificateUrl || ''
            uploadVal.identityPhotoUrl = ''
            uploadVal.proxyIdentityPhotoUrl = ''
            uploadVal.delegationUrl = ''
            uploadVal.otherUrl = ''
          }
        }
        if (data.userType == 2) {
          uploadVal.companyCertificateUrl = ''
          uploadVal.identityPhotoUrl = data.identityPhotoUrl
          uploadVal.identityPhotoBackUrl = data.identityPhotoBackUrl
          uploadVal.proxyIdentityPhotoUrl = data.proxyIdentityPhotoUrl
          uploadVal.proxyIdentityPhotoBackUrl = data.proxyIdentityPhotoBackUrl
          uploadVal.delegationUrl = data.delegationUrl
          uploadVal.otherUrl = data.otherUrl || ''
          uploadVal.urlsList.agentUser = data.identityPhotoUrl.split(',')
          uploadVal.urlsList.agentUserBack =
            data.identityPhotoBackUrl.split(',')
          uploadVal.urlsList.agentProxy = data.proxyIdentityPhotoUrl.split(',')
          uploadVal.urlsList.proxyIdentityPhotoBackUrl =
            data.proxyIdentityPhotoBackUrl.split(',')
          uploadVal.urlsList.agentTrust = data.delegationUrl.split(',')
          uploadVal.urlsList.agentOther = data.otherUrl.split(',')
          uploadVal.getUserList(data.identityPhotoUrl.split(','))
          uploadVal.getUserBackList(data.identityPhotoBackUrl.split(','))
          uploadVal.getProxyList(data.proxyIdentityPhotoUrl.split(','))
          uploadVal.getProxyBackList(data.proxyIdentityPhotoBackUrl.split(','))
          uploadVal.getTrustList(data.delegationUrl.split(','))
          uploadVal.getOtherList(data.otherUrl.split(','))
        }
      })

      if (data.id) {
        ruleForm.signature = ''
      }
      typeOptions.userElectronicType = data.userElectronicType.split(',')
      typeOptions.agentElectronicType = data.agentElectronicType.split(',')

      signs.forEach((item) => {
        const img = data[item.title]
        if (img) {
          item.isHavingImg = true
          item.img = getUrl + img
          signSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
        }
      })
    }
    const confirmGetCode = async () => {
      if (verifyCode.value) {
        await verifyCodeFun(verifyCode.value)
        cancelGetCode()
      }
    }

    const clearSignature = async (current: any) => {
      const val: any = current.bindField
      // const fieldItem = current.bindField
      val.clear()
    }

    const reset = async (current: any) => {
      current.isHavingImg = false
      clearSignature(current)
    }

    const userTypeFun = (user: number, proxy: number) => {
      caseUserType.value = user
      proxyType.value = proxy
    }
    //当事人类型筛选
    const nextUserTypeFun = (obj: any) => {
      caseUserType.value = obj.user.value
      const detalutEmail1: any = ['2']
      const detalutEmail2: any = []
      if (caseUserType.value == 2) {
        typeOptions.agentElectronicType = detalutEmail1
      } else {
        typeOptions.agentElectronicType = detalutEmail2
      }

      proxyType.value = obj.proxy.value
      isShow.value = 2
    }

    let tranferStatus: number

    onMounted(async () => {
      const res = await AxiosAdapter.get<null, number>({
        url:
          '/confirmAddress/getConfirmVersionConfig?institutionId=' +
          route.query.institutionId
      })
      tranferStatus = res
    })

    const nextUser = (val: any) => {
      if (
        caseUserType.value == 1 &&
        proxyType.value == 2 &&
        tranferStatus == 1
      ) {
        caseUserType.value = 2
        proxyType.value = 5
      }

      isShow.value = 3
      redisKey.value = val
    }

    // 签名
    const signreturnEvent = () => {
      signShow.value = false
    }

    const signEvent = (e: any) => {
      const val: any = signs[0]
      val.isHavingImg = false
      signShow.value = false
      signImgStr.value = e
    }

    const signShowFun = () => {
      signShow.value = true
    }

    return {
      submit,
      goBack,
      modifyMessage,
      proxyType,
      caseUserType,
      getStorage,
      getUrl,
      urls,
      agentSignature,
      signature,
      userType,
      courtName,
      signs,
      ruleForm,
      clearSignature,
      reset,
      dialogVisible,
      areaName,
      newData,
      identityTypeList,
      isElectronicList,
      verifyCodeFun,
      confirmGetCode,
      verifyCode,
      cancelGetCode,
      areaOptions,
      agentAreaOptions,
      nextUser,
      typeOptions,
      isShow,
      nextUserTypeFun,
      userTypeFun,
      userUpload,
      signreturnEvent,
      signEvent,
      signShowFun,
      signShow,
      signImgStr,
      signSrc
    }
  }
})
