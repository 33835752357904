import request from './Axios'
import { AxiosResponse, Method } from 'axios'
import { ResponseData, StringFunctionType } from '@/commons/Types'

const methodAndParams: StringFunctionType<Request<any>, Promise<AxiosResponse<ResponseData<any>>>> = {
    get(req) {
        return request.request({
            url: req.url,
            method: 'get',
            params: req.data
        })
    },
    GET(req) {
        return request.request({
            url: req.url,
            method: 'get',
            params: req.data
        })
    },
    post(req) {
        return request.request({
            url: req.url,
            method: 'post',
            data: req.data
        })
    },
    POST(req) {
        return request.request({
            url: req.url,
            method: 'post',
            data: req.data
        })
    }
}

export interface Request<T> {
    type?: Method;
    url: string;
    data?: T;
}

export default class AxiosAdapter {
    // 统一请求处理方法
   
    static request<T, K>(req: Request<T>): Promise<K> {
        if (!req.type) req.type = 'get';
        const func = methodAndParams[req.type];  // 需要确保 methodAndParams 定义了请求方法
        
        return func(req)
            .then((res: AxiosResponse) => {
                // 返回响应体中的数据
                return res.data.value;
            })
            .catch((error) => {
                // 捕获错误并处理后端返回的错误信息
                if (error.response) {
                    // 后端返回了错误信息
                    const errorMessage = error.response.data.msg|| 'Unknown error from server';
                    const errorDetails = error.response.data.details || '';
                    return Promise.reject({
                        message: errorMessage,
                        details: errorDetails,
                        status: error.response.status,
                    });
                } else if (error.request) {
                    // 请求发出，但没有收到响应
                    return Promise.reject({
                        message: 'No response from the server',
                        details: error.request,
                    });
                } else {
                    // 请求设置时发生的错误
                    return Promise.reject({
                        message: 'Request setup error',
                        details: error.message,
                    });
                }
            });
    }

    // GET 请求
    static get<T, K>(req: Request<T>): Promise<K> {
        if (req) req.type = 'get';
        return AxiosAdapter.request<T, K>(req);
    }

    // POST 请求
    static post<T, K>(req: Request<T>): Promise<K> {
        if (req) req.type = 'post';
        return AxiosAdapter.request<T, K>(req);
    }
}
