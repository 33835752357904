// 手机号正则
export const mobileReg = /^1[3456789]\d{9}$/;

// 座机号
export const mobileCallReg = /^(0\d{2,3}-)?([2-9]\d{6,7})(-\d{1,6})?$/;

//身份证正则
export const IDReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
// 中国公民护照
export const passportRegex = /^.{0,9}$/;
//统一社会信用代码
export const usccRegex =  /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
                       
//律师证号
export const lawyerCardRegex = /^[1][A-Z0-9]{2}[A-Z0-9]{2}[0-9]{4}[0-9][0-1][0-9]{6}$/;
//电子邮件正则
// export const emailReg = /^[A-Za-z0-9_-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

// export const imageReg = /^https?:\/\/.*?(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif)$/i;

export const imageReg = /^.*?(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif)$/;

// export const wordReg = /^https?:\/\/.*?(docx)$/i; 
export const wordReg = /^.*?(docx)$/i;

export const pdfReg = /^.*?(pdf)$/i;

export const acceptImage = 'image/jpeg,image/png,image/jpg,image/JPG,image/PNG,image/JPEG'
// export const pdfReg = /^https?:\/\/.*?(pdf)$/i;
